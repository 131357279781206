import React from "react";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Delete} from "@mui/icons-material";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

const GenericRead = (
    {
        title,
        titleAdd,
        linkAdd,
        iterate,
        iterateKey,
        onDelete,
    }
) => {
    const {t} = useTranslation();
    return (
        <Card>
            <h1>{title}</h1>

            <Button style={{margin: "8px", marginLeft: '0'}} variant="contained" color="primary">
                <Link to={linkAdd}>
                    {t("general.create")} {titleAdd}
                </Link>
            </Button>

            {iterate.length ?
                <Table className={'thead-bold'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'} className={'capitalize'}>{titleAdd}</TableCell>
                            <TableCell align={'left'}>{t("general.actions")}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            iterate.map((iterateNow, index) =>
                                <TableRow key={index}>
                                    <TableCell>{iterateNow[iterateKey]}</TableCell>
                                    <TableCell>
                                        <Delete onClick={() => onDelete(iterateNow.id)} className={'pointer'}/>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table> :
                <p>{t("genericRead.notFound", {title: titleAdd})}</p>
            }
        </Card>
    );
};

export default GenericRead;
