import axios from "axios";
import {failureCallback, getUrl, successCallback} from "../index";

export default {
    addCourse: async (token, name) => {
        return await axios.get(getUrl('createCourse'), {
            params: {
                token: token,
                name: name
            }
        }).then(successCallback, failureCallback);
    },
    getCourses: async (token) => {
        return await axios.get(getUrl('getCourses'), {
            params: {
                token,
            },
        }).then(successCallback, failureCallback);
    },
    editCourse: async (token, idCourse, name) => {
        return await axios.get(getUrl('editCourse'), {
            params: {
                token: token,
                course: idCourse,
                name: name,
            }
        }).then(successCallback, failureCallback);
    },
    deleteCourse: async (token, idCourse) => {
        return await axios.get(getUrl('removeCourse'), {
            params: {
                token: token,
                course: idCourse,
            }
        }).then(successCallback, failureCallback);
    }
};
