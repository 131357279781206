import { createSlice } from "@reduxjs/toolkit";

const documentsSlicer = createSlice({
    name: 'documents',
    initialState: {
        documents: [],
        documentsUser: [],
        documentsLibrary: [],
    },
    reducers: {
        setDocuments: (state, action) => {
            state.documents = action.payload;
        },
        setDocumentsUser: (state, action) => {
            state.documentsUser = action.payload;
        },
        setDocumentsLibrary: (state, action) => {
            state.documentsLibrary = action.payload;
        }
    },
});

// getters
export const selectDocuments = (state) => state.documents.documents;
export const selectDocumentsUser = (state) => state.documents.documentsUser;
export const selectDocumentsLibrary = (state) => state.documents.documentsLibrary;

// actions
export const { setDocuments, setDocumentsUser, setDocumentsLibrary } = documentsSlicer.actions;

// slice
export default documentsSlicer;
