import axios from "axios";
import {failureCallback, getUrl, successCallback} from '../index';

export default {
    auth: async (email, password, language) => {
        return await axios.get(getUrl('login'), {
            params: {
                email: email,
                password: password,
                lang: language,
            },
        }).then(successCallback, failureCallback);
    },
    create: async (email, fullName, language) => {
        return await axios.get(getUrl('createUser'), {
            params: {
                email: email,
                fullName: fullName,
                lang: language
            },
        }).then(successCallback, failureCallback);
    },
    confirm: async (password, code, language) => {
        return await axios.get(getUrl('confirmAccount'), {
            params: {
                password: password,
                code: code,
                lang: language
            },
        }).then(successCallback, failureCallback);
    },
    logout: async (token) => {
        return await axios.get(getUrl('logout'), {
            params: {
                token: token
            },
        }).then(() => localStorage.clear(), failureCallback);
    },
    forgot: async (email, language) => {
        return await axios.get(getUrl('forgotPassword'), {
            params: {
                email: email,
                lang: language
            },
        }).then(successCallback, failureCallback);
    },
    setLocale: async(token, language) => {
        return await axios.get(getUrl('setLocale'), {
            params: {
                token: token,
                lang: language
            }
        }).then(successCallback, failureCallback);
    },
};
