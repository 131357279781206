import { createSlice } from "@reduxjs/toolkit";

const activitiesSlicer = createSlice({
    name: 'activities',
    initialState: {
        activities: [],
        orphanActivities: [],
    },
    reducers: {
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
        setOrphanActivities: (state, action) => {
            state.orphanActivities = action.payload;
        }
    },
});

// getters
export const selectActivities = (state) => state.activities.activities;
export const selectOrphanActivities = (state) => state.activities.orphanActivities;

// actions
export const { setActivities, setOrphanActivities } = activitiesSlicer.actions;

// slice
export default activitiesSlicer;
