import React, {useEffect, useState} from "react";
import {useNavigate, useParams,} from "react-router-dom";
import loginApi from "api/routes/login";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useUser} from "hooks/useUser";
import {handleDataServer, handleSuccessData} from "api";
import {useDispatch} from "react-redux";
import {setError} from "store/modules/Error";

const ConfirmRecover = () => {
    const {t, i18n} = useTranslation();
    const {code} = useParams();
    const user = useUser();
    const dispatch = useDispatch();

    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [first, setFirst] = useState(false);

    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();
        if (pass !== confirmPass) return;

        const currentLanguage = i18n.language.substring(0, 2);
        const auth = await handleDataServer(await loginApi.confirm(pass, code, currentLanguage), handleSuccessData);

        if (auth) {
            alert(t("auth.confirm.passwordSet"));
            navigate("/");
        }
    }

    const checkConfirmPass = () => {
        if (!first) {
            setFirst(true);
            return;
        }

        if (pass === confirmPass && pass !== "") {
            dispatch(setError(""));
        } else {
            dispatch(setError(t('auth.confirm.passwordDiff')));
        }
    };

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, []);

    return (
        <form className={'auth-form'}>
            <p className={'marginTop0'}>{t("auth.confirm.title")}</p>
            <TextField
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                onBlur={() => checkConfirmPass()}
                label={t("general.password")}
                variant="outlined"
                type={'password'}
                className={'marginBottom8'}
            />
            <TextField
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
                onBlur={() => checkConfirmPass()}
                label={t("auth.confirm.confirmPassword")}
                variant="outlined"
                type={'password'}
                className={'marginBottom8'}
            />

            <Button
                onClick={handleSubmit}
                variant="contained"
            >
                {t("auth.confirm.setPassword")}
            </Button>
        </form>
    );
};

export default ConfirmRecover;
