import axios from 'axios';
import {failureCallback, getUrl, successCallback} from "../index";

export default {
    addOntology: async (token, form) => {
        return await axios.post(getUrl('addOntology'), form, {
            method: 'POST',
            params: {
                token: token,
            },
            data: form,
            headers: { "Content-Type": "multipart/form-data" }
        }).then(successCallback, failureCallback);
    },
    getOntologies: async (token) => {
        return await axios.get(getUrl('getOntologies'), {
            params: {
                token: token
            }
        }).then(successCallback, failureCallback);
    },
    deleteOntology: async (token, idOntology) => {
        return await axios.get(getUrl('deleteOntology'), {
            params: {
                token: token,
                ontology: idOntology
            }
        }).then(successCallback, failureCallback);
    },
};
