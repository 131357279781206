import React from "react";
import ReadCourses from "pages/common/courses/read";

/**
 * Página inicial de alunos; apesar de receber apenas a página de cursos
 * e esta lidar com o comportamento específico do usuário, é importante
 * ter uma página específica para cada tipo de usuário, pois, caso seja
 * necessário adicionar mais funcionalidades, poderá ser feito de forma
 * @returns {JSX.Element}
 * @constructor
 */
const IndexStudent = () => {
    return (
        <ReadCourses />
    );
};

export default IndexStudent;
