import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import './style.css';
import {Stack, useMediaQuery} from "@mui/material";
import Button from "@mui/material/Button";
import {ArrowBack, ArrowDownward, ArrowForward, ArrowUpward} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

/**
 * Seleção de múltiplos itens, com possibilidade de mover itens entre as listas.
 * Você pode passar, opcionalmente, a lista da esquerda e a lista da direita,
 * fazendo com que quando clique da esquerda para a direita, chame a função pai
 * (onLeftToRight e onRightToLeft). Do contrário, são percorridos os items e colocados
 * em "selecionados", na direita e não selecionados, na esquerda.
 * @param items Itens padrões que devem aparecer, para serem selecionados ou não
 * @param itemsSelected Itens que já devem aparecer selecionados
 * @param isCentered Se os options devem ficar centralizados
 * @param leftTitle Título dos elementos à esquerda
 * @param rightTitle Título dos elementos à direita
 * @param leftItems Itens à esquerda (override em items)
 * @param rightItems Itens à direita (override em items)
 * @param onLeftSelection Caso o componente pai queira os dados selecionados na esquerda
 * @param onRightSelection Caso o componente pai queira os dados selecionados na direita
 * @param onSelected Caso o componente pai queira os dados selecionados
 * @param onLeftToRight Quando leftItems é passado, leftToRight chama esta função
 * @param onRightToLeft Quando rightItems é passado, rightToLeft chama esta função
 * @param extraButtonName Botão extra, opcional, que trata a seleção dos itens selecionados (de ambos lados)
 * @param extraButtonAction Ação que botão extra deve desempenhar
 * @returns {JSX.Element}
 * @constructor
 */
const SelectMultiple = (
    {
        items = [],
        itemsSelected = [],
        isCentered = false,
        leftTitle,
        rightTitle,
        leftItems,
        rightItems,
        onLeftSelection,
        onRightSelection,
        onSelected,
        onLeftToRight,
        onRightToLeft,
        extraButtonName,
        extraButtonAction
    }
) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState([]);
    const [leftSelected, setLeftSelected] = useState([]);
    const [rightSelected, setRightSelected] = useState([]);

    const leftTitleShow = leftTitle ? leftTitle : t("selectMultiple.leftTitle");
    const rightTitleShow = rightTitle ? rightTitle : t("selectMultiple.rightTitle");

    const isMobile = useMediaQuery('(max-width:900px)');

    const clickOptionMobile = (id, array, setArray) => {
        let intId = parseInt(id);
        let newArray = [...array];

        if (newArray.includes(intId)) {
            newArray.splice(newArray.indexOf(intId), 1);
        } else {
            newArray.push(intId);
        }

        setArray(newArray);
    };

    const convertItems = (items, array, setArray) => items.map(el =>
        <option
            key={el.value}
            value={el.value}
            title={el.text}
            onClick={() => isMobile && clickOptionMobile(el.value, array, setArray)}
            className={array.includes(parseInt(el.value)) && isMobile ? 'selected' : ''}
        >
            {el.text}
        </option>
    );

    const getItems = (leftItems ?
            convertItems(leftItems, leftSelected, setLeftSelected) :
            convertItems(items.filter(el => !selected.includes(parseInt(el.value))), leftSelected, setLeftSelected)
    );

    const getItemsSelected = (rightItems ?
            convertItems(rightItems, rightSelected, setRightSelected) :
            convertItems(items.filter(el => selected.includes(parseInt(el.value))), rightSelected, setRightSelected)
    );

    const getArrayFromSelected = (e) => {
        return Array.from(e.target.selectedOptions, (item) => parseInt(item.value));
    };

    const handleLeftSelection = (e) => {
        const selectedLeft = getArrayFromSelected(e);
        setLeftSelected(selectedLeft);
        if (onLeftSelection) {
            onLeftSelection(selectedLeft);
        }
    };

    const handleRightSelection = (e) => {
        const selectedRight = getArrayFromSelected(e);
        setRightSelected(selectedRight);
        if (onRightSelection) {
            onRightSelection(selectedRight);
        }
    };

    const leftToRight = () => {
        // if it was passed a function to retrieve the selected items, call it
        if (onLeftToRight) {
            onLeftToRight(leftSelected);
            return;
        }

        let beforeSelected = [...selected];
        leftSelected.forEach(el => beforeSelected.push(el));

        setSelected(beforeSelected);
        if (onSelected) {
            onSelected(beforeSelected);
        }

        setLeftSelected([]);
    };

    const rightToLeft = () => {
        // if it was passed a function to retrieve the selected items, call it
        if (onRightToLeft) {
            onRightToLeft(rightSelected);
            return;
        }

        let beforeSelected = [...selected];
        rightSelected.forEach(el => beforeSelected.splice(beforeSelected.indexOf(el), 1));

        setSelected(beforeSelected);
        if (onSelected) {
            onSelected(beforeSelected);
        }

        setRightSelected([]);
    };

    useEffect(() => {
        if (JSON.stringify(selected) !== JSON.stringify(itemsSelected) &&
            leftItems === undefined && rightItems === undefined) {
            setSelected(itemsSelected);
        }
    }, [itemsSelected]);

    return (
        <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} columns={{xs: 4, sm: 4, md: 12}}>
            <Grid item xs={5} className={'maxWidth100'}>
                <p>{leftTitleShow}</p>
                {isMobile &&
                    <div className={'items-mobile'}>
                        {getItems}
                    </div>
                }
                <select
                    multiple
                    className={isCentered ? "center" : ""}
                    onChange={handleLeftSelection}
                    style={{display: isMobile ? 'none' : 'block'}}
                >
                    {getItems}
                </select>
            </Grid>
            <Grid item xs={2}>
                <Stack
                    sx={{flexDirection: {xs: 'row', md: 'column'}}}
                >
                    <Button onClick={leftToRight}>
                        {!isMobile ? <ArrowForward/> : <ArrowDownward/>}
                    </Button>
                    <Button onClick={rightToLeft}>
                        {!isMobile ? <ArrowBack/> : <ArrowUpward/>}
                    </Button>

                    {extraButtonName &&
                        <Button
                            onClick={() => extraButtonAction([leftSelected + rightSelected])}>{extraButtonName}</Button>
                    }
                </Stack>
            </Grid>
            <Grid item xs={5} className={'maxWidth100'}>
                <p>{rightTitleShow}</p>
                {isMobile &&
                    <div className={'items-mobile'}>
                        {getItemsSelected}
                    </div>
                }
                <select
                    multiple
                    className={isCentered ? "center" : ""}
                    onChange={handleRightSelection}
                    style={{display: isMobile ? 'none' : 'block'}}
                >
                    {getItemsSelected}
                </select>
            </Grid>
        </Grid>
    );
};

export default SelectMultiple;
