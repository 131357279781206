import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import {useUser} from "hooks/useUser";
import {useParams} from "react-router-dom";
import usersApi from "api/routes/users";
import {handleDataServer, handleSuccessData} from "api";
import Button from "@mui/material/Button";
import AddUserModal from "components/teacher/modal/AddUserModal";
import UsersTable from "components/teacher/UsersTable";
import {useTranslation} from "react-i18next";
import {openConfirmDelete} from "utils/utils";
import EditUserModal from "components/teacher/modal/EditUserModal";
import {useDispatch, useSelector} from "react-redux";
import {getCourses, selectCourses} from "store/modules/Courses";

const ReadUsers = () => {
    const {t} = useTranslation();
    const user = useUser();

    let {idCourse} = useParams();

    const dispatch = useDispatch();
    const courses = useSelector(selectCourses);

    const [users, setUsers] = useState();

    const [modalClose, setModalClose] = useState(false);

    const [modalEdit, setModalEdit] = useState(false);
    const [userEdit, setUserEdit] = useState();

    const closeModal = async (update) => {
        setModalClose(false);
        setModalEdit(false);
        if (update) {
            await populate();
        }
    };

    const handleDelete = async (flag, id) => {
        if (flag) {
            const role = users.find(el => el.id === id).role;

            let result = await usersApi.removeMemberCourse(user.access_token, idCourse, id, `${role}`);
            await handleDataServer(result, handleSuccessData);
            await populate();
        }
    };

    const handleEdit = async(id) => {
        setUserEdit(users.find(el => el.id === id));
        setModalEdit(true);
    };

    const populateUsers = async () => {
        let usersResult = await usersApi.getMembersCourse(user.access_token, idCourse);
        let usersData = await handleDataServer(usersResult, (data) => data.data.members);

        if (usersData) {
            setUsers(usersData);
        }
    };

    const populate = async () => {
        await populateUsers();
        dispatch(getCourses(user.access_token));
    };

    const getCourseName = () => {
        if (!courses.length) return "";

        const course = courses.find(el => el.id === idCourse);

        return course.name ? course.name : "";
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <Card>
            <h1>{t("users.read.title")} - {t("courses.titleNew")} {getCourseName()}</h1>

            <Button
                variant="contained"
                color="primary"
                onClick={() => setModalClose(true)}
            >
                {t("general.add")}
            </Button>

            <UsersTable
                isCourse={true}
                hasCheckbox={false}
                users={users}
                hasRole={true}
                addActions={true}
                showChips={false}
                onSelection={() => {
                }}
                handleDelete={(id) => openConfirmDelete("users.singleTitle", handleDelete, id)}
                handleEdit={(id) => handleEdit(id)}
            />

            <AddUserModal
                usersAdded={users}
                open={modalClose}
                handleClose={closeModal}
            />

            <EditUserModal
                open={modalEdit}
                handleClose={closeModal}
                user={userEdit}
                isCourse={true}
            />
        </Card>
    );
};

export default ReadUsers;
