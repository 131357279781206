import React, {useEffect} from "react";
import {useUser} from "hooks/useUser";
import {useDispatch, useSelector} from "react-redux";
import {selectOntologies, setOntologies} from "store/modules/Ontologies";
import ontologiesApi from "api/routes/ontologies";
import {handleDataServer, handleSuccessData} from "api";
import GenericRead from "components/teacher/GenericRead";
import {useTranslation} from "react-i18next";
import {openConfirmDelete} from "utils/utils";

const Ontologies = () => {
    const {t} = useTranslation();
    const user = useUser();
    const dispatch = useDispatch();
    const ontologies = useSelector(selectOntologies);

    const deleteOntology = async (flag, idOntology) => {
        if (flag) {
            let result = await ontologiesApi.deleteOntology(user.access_token, idOntology);
            await handleDataServer(result, handleSuccessData);
            await populate();
        }
    };

    const populateOntologies = async () => {
        let ontologiesResult = await ontologiesApi.getOntologies(user.access_token);
        let ontologiesData = await handleDataServer(ontologiesResult, (data) => data.data.ontologies);

        if (ontologiesData) {
            dispatch(setOntologies(ontologiesData));
        }
    };

    const populate = async () => {
        await populateOntologies();
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <GenericRead
            title={t("ontologies.title")}
            titleAdd={t("ontologies.singleTitle")}
            linkAdd={`/teacher/ontologies/new`}
            iterate={ontologies}
            iterateKey="name"
            onDelete={(id) => openConfirmDelete("ontologies.singleTitle", deleteOntology, id)}
        />
    );
};

export default Ontologies;
