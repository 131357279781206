import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import coursesApi from 'api/routes/courses';

import Card from "@mui/material/Card";
import {useUser} from "hooks/useUser";
import {useNavigate, useParams} from "react-router-dom";
import {handleDataServer} from "api";
import {useSelector} from "react-redux";
import {selectCourses} from "store/modules/Courses";
import {useTranslation} from "react-i18next";

/**
 * Faz a inserção/atualização de um Curso
 * @returns {JSX.Element} Elemento de criação/atualização de um Curso
 * @constructor Sem parâmetros do component, pois são pegos pela url {@see routes.js}
 */
const NewCourse = () => {
    const {t} = useTranslation();
    const user = useUser();
    const navigate = useNavigate();
    const [name, setName] = useState("");

    let {idCourse} = useParams();
    const isEdit = idCourse !== undefined;

    const courses = useSelector(selectCourses);
    const course = isEdit ? courses.find(el => el.id === idCourse) : null;

    const addCourseAux = async (response) => {
        if (response.status === 200) {
            navigate(-1);
        }
    };

    const addCourse = async () => {
        let result = isEdit ?
            await coursesApi.editCourse(user.access_token, idCourse, name) :
            await coursesApi.addCourse(user.access_token, name);

        await handleDataServer(result, addCourseAux);
    };

    useEffect(() => {
        if (isEdit && course) {
            setName(course.name);
        }
    }, []);

    return (
        <>
            <Card>
                <form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h1>
                        {`${t(`general.${isEdit ? 'edit' : 'create'}`)} ${t("courses.titleNew")}`}
                    </h1>

                    <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        id="outlined-basic"
                        label={t("courses.create.nameCourse")}
                        variant="outlined"
                        className={'marginBottom8'}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addCourse}
                    >
                        {t(`general.${isEdit ? 'edit' : 'add'}`)}
                    </Button>
                </form>
            </Card>
        </>
    );
};

export default NewCourse;
