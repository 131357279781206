import React from "react";
import {useUser} from "hooks/useUser";
import utils from "utils/utils";
import Template from "pages/common/Template";
import {Navigate} from "react-router-dom";

const ProtectedStudent = () => {
    const user = useUser();

    return (
        <>
            {(user && user.role === utils.roles.student) ?
                <Template/> :
                <Navigate to={'/'} />
            }
        </>
    );
};

export default ProtectedStudent;
