import React, {useState} from "react";
import {Link} from "react-router-dom";
import loginApi from "api/routes/login";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {handleDataServer, handleSuccessData} from "api";

const CreateUser = () => {
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [createBool, setCreateBool] = useState("");

    async function handleSubmit(e) {
        e.preventDefault();
        const currentLanguage = i18n.language.substring(0, 2);
        const create = await handleDataServer(await loginApi.create(email, fullName, currentLanguage), handleSuccessData);

        if (create) {
            setCreateBool(true);
        }
    }

    return (
        <form className={'auth-form'}>
            {createBool ? (
                <>
                    <p>{t("auth.create.line0")}</p>
                    <p>{t("auth.create.line1")}</p>
                </>
            ) : (
                <>
                    <p className={'marginTop0'}>
                        {t("auth.create.title")}
                    </p>

                    <TextField
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        id="outlined-basic"
                        label={t("auth.create.fullName")}
                        variant="outlined"
                        className={'marginBottom8'}
                    />
                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="outlined-basic"
                        label={t("general.email")}
                        variant="outlined"
                        className={'marginBottom8'}
                    />

                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        {t("general.create")}
                    </Button>
                </>
            )}


            <small style={{color: "gray", textAlign: "center"}}>
                <br></br>
                <Link to="/">
                    <u>{t("auth.backIndex")}</u>
                </Link>
            </small>
        </form>
    );
};

export default CreateUser;
