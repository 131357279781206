import axios from 'axios';
import {failureCallback, getUrl, successCallback} from '../index';

export default {
    addDocument: async (token, form) => {
        return await axios.post(getUrl('addDocument'), form, {
            method: 'POST',
            params: {
                token: token,
            },
            data: form,
            headers: { "Content-Type": "multipart/form-data" }
        }).then(successCallback, failureCallback);
    },
    getDocuments: async (token, idActivity) => {
        return await axios.get(getUrl('getDocuments'), {
            params: {
                token: token,
                activity: idActivity
            },
        }).then(successCallback, failureCallback);
    },
    getDocument: async (token, idDocument) => {
        return await axios.get(getUrl('getDocument'), {
            params: {
                token: token,
                document: idDocument
            },
        }).then(successCallback, failureCallback);
    },
    deleteDocument: async(token, idDocumeunt) => {
        return await axios.get(getUrl('deleteDocument'), {
            params: {
                token: token,
                document: idDocumeunt
            }
        }).then(successCallback, failureCallback);
    },
    getDocumentsLibrary: async (token) => {
        return await axios.get(getUrl('getDocumentsDL'), {
            params: {
                token: token
            },
        }).then(successCallback, failureCallback);
    },
    getDocumentsUser: async (token) => {
        return await axios.get(getUrl('getMyDocuments'), {
            params: {
                token: token
            },
        });
    }
};
