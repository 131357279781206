import { createSlice } from "@reduxjs/toolkit";

const errorSlicer = createSlice({
    name: 'errors',
    initialState: {
        error: "",
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

// getters
export const selectError = (state) => state.errors.error;

// actions
export const { setError } = errorSlicer.actions;

// slice
export default errorSlicer;
