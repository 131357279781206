import React from "react";
import {createHashRouter} from "react-router-dom";

// Auth Pages
import Login from "./pages/auth/Login";
import Create from "./pages/auth/Create";
import Forgot from "./pages/auth/Forgot";
import ConfirmRecover from "./pages/auth/ConfirmRecover";

// Student pages
import IndexStudent from "./pages/student/IndexStudent";

// Teacher pages
import IndexTeacher from "./pages/teacher/IndexTeacher";
import Documents from "./pages/teacher/documents/read";
import NewDocuments from "./pages/teacher/documents/new";
import Ontologies from "./pages/teacher/ontologies/read";
import NewOntologies from "./pages/teacher/ontologies/new";
import NewCourse from "./pages/teacher/courses/new";
import NewActivity from "./pages/teacher/activities/new";
import Activities from "./pages/teacher/activities/read";
import ReadUsers from "./pages/teacher/users/read";
import ProtectedTeacher from "./pages/teacher/ProtectedTeacher";
import ProtectedStudent from "./pages/student/ProtectedStudent";
import AuthTemplate from "./pages/common/AuthTemplate";
import ChangeRole from "pages/teacher/users/ChangeRole";

const router = createHashRouter([
    // teacher routes
    {
        path: "/teacher",
        element: <ProtectedTeacher/>,
        children: [
            {
                index: true,
                element: <IndexTeacher/>
            },
            {
                path: 'users',
                children: [
                    {
                        index: true,
                        element: <ChangeRole/>
                    }
                ],
            },
            {
                path: 'documents',
                children: [
                    {
                        index: true,
                        element: <Documents/>,
                    },
                    {
                        path: 'new',
                        element: <NewDocuments/>,

                    }
                ]
            },
            {
                path: 'ontologies',
                children: [
                    {
                        index: true,
                        element: <Ontologies/>,
                    },
                    {
                        path: 'new',
                        element: <NewOntologies/>
                    }
                ]
            },
            {
                path: 'courses',
                children: [
                    {
                        path: 'new',
                        element: <NewCourse/>
                    },
                    {
                        path: ':idCourse/edit',
                        element: <NewCourse/>
                    },
                    {
                        path: ':idCourse/activities',
                        children: [
                            {
                                index: true,
                                element: <Activities/>
                            },
                            {
                                path: 'new',
                                element: <NewActivity/>
                            },
                            {
                                path: ':idActivity/edit',
                                element: <NewActivity/>
                            }
                        ],
                    },
                    {
                        path: ':idCourse/users',
                        children: [
                            {
                                index: true,
                                element: <ReadUsers/>
                            }
                        ]
                    }
                ]
            }
        ]
    },

    // user routes
    {
        path: '/student',
        element: <ProtectedStudent/>,
        children: [
            {
                index: true,
                element: <IndexStudent/>
            }
        ],
    },

    // auth routes (unprotected)
    {
        element: <AuthTemplate/>,
        path: '/',
        children: [
            {
                index: true,
                element: <Login/>,
            },
            {
                path: '/create',
                element: <Create/>,
            },
            {
                path: '/forgot',
                element: <Forgot/>,
            },
            {
                path: '/confirm/:code',
                element: <ConfirmRecover/>,
            },
            {
                path: '/recover/:code',
                element: <ConfirmRecover/>,
            }
        ],
    },
]);

export default router;
