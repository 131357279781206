import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import coursesApi from "api/routes/courses";
import documentsApi from "api/routes/documents";
import {handleDataServer} from "api";
import utils from "utils/utils";

const coursesSlicer = createSlice({
    name: 'courses',
    initialState: {
        courses: [],
    },
    reducers: {
        setCourses: (state, action) => {
            state.courses = action.payload;
        }
    },
    extraReducers(builder) {
        // get courses
        builder
            .addCase(getCourses.fulfilled, (state, action) => {
                state.courses = action.payload;
            });
    }
});

// thunk functions (async)
export const getCourses = createAsyncThunk("courses/read",
    async (token, thunkAPI) => {
        let coursesResult = await coursesApi.getCourses(token);
        let coursesParse = await handleDataServer(coursesResult, populateIntermediate);

        // save partial data to speed up the requests and show to the user
        thunkAPI.dispatch(setCourses(coursesParse));

        // get documents for each activity
        let courseAllData = await populateAux(coursesParse);

        // post-process data
        if (courseAllData) {
            return courseAllData;
        }
    });

const populateIntermediate = async (coursesResult) => {
    let courseAllData = [];
    for (const course of coursesResult.data.courses) {
        let activities = course.activities;
        activities.documents = [];
        courseAllData.push({
            id: course.id,
            role: course.role,
            name: course.name,
            activities: activities
        });
    }

    return courseAllData;
};

const populateAux = async (coursesNow) => {
    const user = utils.parseUser();
    let courses = JSON.parse(JSON.stringify(coursesNow));

    for (const course of courses) {
        for (let activity of course.activities) {
            let documents = await documentsApi.getDocuments(user.access_token, activity.id);
            activity.documents = await handleDataServer(documents, (data) => data.data.documents);
        }
    }

    return courses;
};

// getters
export const selectCourses = (state) => state.courses.courses;

// actions
export const {setCourses} = coursesSlicer.actions;

// slice
export default coursesSlicer;
