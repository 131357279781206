import React from "react";
import {Link} from "react-router-dom";
import statsApi from "api/routes/stats";
import {useUser} from "hooks/useUser";
import {handleDataServer} from "api";
import {useTranslation} from "react-i18next";
import TooltipIcon from "../TooltipIcon";
import {useMediaQuery} from "@mui/material";

const ActivityManagement = (
    {
        course,
        activity
    }
) => {
    const user = useUser();
    const {t} = useTranslation();
    const isMobile = useMediaQuery('(max-width:900px)');

    const genericHandleDataServer = async (result, type) => {
        let data = await handleDataServer(result, (dataServer) => dataServer.data);

        // make download
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${activity.id}-${type}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const getStatsFree = async (e) => {
        e.preventDefault();
        let result = await statsApi.getStatsFree(user.access_token, activity.id);
        await genericHandleDataServer(result, 'free');
    };

    const getStatsSemantic = async (e) => {
        e.preventDefault();
        let result = await statsApi.getStatsSemantic(user.access_token, activity.id);
        await genericHandleDataServer(result, 'semantic');
    };

    const getStatsTelemetry = async (e) => {
        e.preventDefault();
        let result = await statsApi.getTelemetry(user.access_token, activity.id);
        await genericHandleDataServer(result, 'telemetry');
    };

    return (
        <div className={isMobile ? '' : 'atividades-container'}>
            <Link to={`/teacher/courses/${course.id}/activities/${activity.id}/edit`}>
                <TooltipIcon tooltipText={"general.edit"} icon={"edit"}/>
            </Link>

            {activity.stat_free === "1" &&
                <Link to={''} onClick={getStatsFree}>
                    <TooltipIcon tooltipText={"stats.stats_free"} icon={'show_chart'}/>
                </Link>
            }

            {activity.stat_semantic === "1" &&
                <Link to={''} onClick={getStatsSemantic}>
                    <TooltipIcon tooltipText={"stats.stats_semantic"} icon={'schema'}/>
                </Link>
            }

            <Link to={''} onClick={getStatsTelemetry}>
                <TooltipIcon tooltipText={"stats.telemetry"} icon={'query_stats'}/>
            </Link>
        </div>
    );
};

export default ActivityManagement;
