import {configureStore} from "@reduxjs/toolkit";
import coursesSlicer from './modules/Courses';
import ontologiesSlicer from "./modules/Ontologies";
import documentsSlicer from "./modules/Documents";
import activitiesSlicer from "./modules/Activities";
import errorSlicer from "./modules/Error";
import deleteDialogSlicer from "./modules/DeleteDialog";

const store = configureStore({
    reducer: {
        courses: coursesSlicer.reducer,
        ontologies: ontologiesSlicer.reducer,
        documents: documentsSlicer.reducer,
        activities: activitiesSlicer.reducer,
        errors: errorSlicer.reducer,
        deleteDialog: deleteDialogSlicer.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});

export default store;
