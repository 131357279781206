import {decode} from "html-entities";
import store from "store/store";
import {setError} from "store/modules/Error";

export const RAW_SERVER_URI = "https://dlnotes3.ufsc.br";

export const SERVER_URI = RAW_SERVER_URI + "/api";

export const getUrl = (path) => {
    return `${SERVER_URI}/${path}`;
};
export const successCallback = (response) => {
    return response;
};

export const failureCallback = (error) => {
    store.dispatch(setError(decode(error)));
    return false;
};

export const handleSuccessData = async(dataServer) => {
    return dataServer.data.error == null;
};

const tokenExpired = [
    'invalid token',
    'token inválido'
];

export const handleDataServer = async (dataServer, func) => {
    if (dataServer && dataServer.data.error == null) {
        return await func(dataServer);
    } else if (dataServer && dataServer.data.error) {
        let error = decode(dataServer.data.error);
        let errorString;
        if (Array.isArray(error)) {
            errorString = error.map(el => el.message).join(" - ");
        } else {
            errorString = error;
        }

        // checar se erro foi devido a token expirado
        if (tokenExpired.includes(errorString.toLowerCase())) {
            localStorage.clear();
            window.location.href = "/";
            return;
        }

        store.dispatch(setError(errorString));
    } else {
        console.log(dataServer);
        window.alert("Ops, não foi possível fazer a chamada para o sistema. Tente novamente mais tarde.");
    }
    return null;
};
