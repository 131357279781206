import React from "react";
import ReadCourses from "pages/common/courses/read";

const IndexTeacher = () => {
    return (
        <ReadCourses/>
    );
};

export default IndexTeacher;
