import axios from "axios";
import {failureCallback, getUrl, successCallback} from "../index";

const generalStats = async(token, idActivity, route) => {
    return await axios.get(getUrl(route), {
        params: {
            token: token,
            activity: idActivity
        }
    }).then(successCallback, failureCallback);
};

export default {
    getStatsFree: async(token, idActivity) => {
        return await generalStats(token, idActivity, 'stats_free');
    },
    getStatsSemantic: async(token, idActivity) => {
        return await generalStats(token, idActivity, 'stats_semantic');
    },
    getTelemetry: async(token, idActivity) => {
        return await generalStats(token, idActivity, 'telemetry');
    },
};
