import { createSlice } from '@reduxjs/toolkit';

const ontologiesSlicer = createSlice({
    name: 'ontologies',
    initialState: {
        ontologies: [],
    },
    reducers: {
        setOntologies: (state, action) => {
            state.ontologies = action.payload;
        }
    },
});

// getters
export const selectOntologies = (state) => state.ontologies.ontologies;

// actions
export const { setOntologies } = ontologiesSlicer.actions;

// slice
export default ontologiesSlicer;
