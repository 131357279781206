import React, {useEffect} from "react";
import Card from "@mui/material/Card";
import {Link, useParams} from "react-router-dom";
import {useUser} from "hooks/useUser";
import {useDispatch, useSelector} from "react-redux";
import {getCourses, selectCourses} from "store/modules/Courses";
import Button from "@mui/material/Button";
import SelectMultiple from 'components/SelectMultiple/SelectMultiple';
import activitiesApi from 'api/routes/activities';
import {handleDataServer, handleSuccessData} from "api";
import {selectActivities, selectOrphanActivities, setActivities, setOrphanActivities} from "store/modules/Activities";
import utils, {openConfirmDelete} from "utils/utils";
import {useTranslation} from "react-i18next";

const Activities = () => {
    const dispatch = useDispatch();
    const user = useUser();
    const {t} = useTranslation();

    let {idCourse} = useParams();
    const courses = useSelector(selectCourses);

    const activities = useSelector(selectActivities);
    const orphanActivities = useSelector(selectOrphanActivities);

    const handleLeftToRight = (leftSelected) => {
        leftSelected.forEach(async (el) => {
            let result = await activitiesApi.removeActivityCourse(user.access_token, idCourse, el);
            await handleDataServer(result, handleSuccessData);
        });
        setTimeout(() => populate().then(el => el), 1000);
    };
    const handleRightToLeft = (rightSelected) => {
        rightSelected.forEach(async (el) => {
            let result = await activitiesApi.addActivityCourse(user.access_token, idCourse, el);
            await handleDataServer(result, handleSuccessData);
        });
        setTimeout(() => populate().then(el => el), 1000);
    };

    const handleDeleteActivity = async (flag, selected) => {
        if (flag) {
            selected.forEach(async (el) => {
                let result = await activitiesApi.deleteActivity(user.access_token, el);
                await handleDataServer(result, handleSuccessData);
            });
            populate().then(el => el);
        }
    };

    const activitiesAux = async (data) => {
        return data.data.activities;
    };

    const populateOrphanActivities = async () => {
        let orphanActivitiesResult = await activitiesApi.getOrphanActivities(user.access_token);
        let orphanActivitiesData = await handleDataServer(orphanActivitiesResult, activitiesAux);
        if (orphanActivitiesData) {
            dispatch(setOrphanActivities(orphanActivitiesData));
        }
    };

    const populateActivities = async () => {
        let activitiesResult = await activitiesApi.getActivities(user.access_token, idCourse);
        let activitiesData = await handleDataServer(activitiesResult, activitiesAux);
        if (activitiesData) {
            dispatch(setActivities(activitiesData));
        }
    };

    const populate = async () => {
        await populateActivities();
        await populateOrphanActivities();
    };

    useEffect(() => {
        if (courses.length === 0) {
            dispatch(getCourses(user.access_token));
        }

        populate().then(el => el);
    }, []);

    return (
        <Card>
            <h1>{courses.find(el => el.id === idCourse) ? courses.find(el => el.id === idCourse).name : ""} -
                {t("activities.title")}</h1>

            <Button
                variant="contained"
                color="primary"
            >
                <Link to={`/teacher/courses/${idCourse}/activities/new`}>
                    {t("activities.read.newActivity")}
                </Link>
            </Button>

            <SelectMultiple
                leftItems={utils.makeOptionsItems(activities)}
                rightItems={utils.makeOptionsItems(orphanActivities)}
                onLeftToRight={handleLeftToRight}
                onRightToLeft={handleRightToLeft}
                leftTitle={t("activities.read.courseActivities")}
                rightTitle={t("activities.read.orphanActivities")}
                isCentered={true}
                extraButtonName={t("activities.read.deleteActivity")}
                extraButtonAction={(selected) => openConfirmDelete("activities.titleNew", handleDeleteActivity, selected)}
            />
        </Card>
    );
};

export default Activities;
