import store from "store/store";
import {openDialog} from "store/modules/DeleteDialog";

const utils = {
    roles: {
        student: "0",
        teacher: "1",
        admin: "2"
    },
    rolesCoursesNames: {
        // 0: "student",
        1: "moderator",
        2: "student", // teacher
    },
    rolesNames: {
        0: "student",
        1: "teacher",
        2: "administrator" // administrator
    },
    makeOptionsItems: (items, idParam = "id", nameParam = "name") => {
        let itemsReturn = [];

        if (items && items.length) {
            itemsReturn = items.map(item =>
                ({value: item[idParam], text: item[nameParam]})
            );
        }

        return itemsReturn;
    },
    isProtected: () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return user.role === utils.roles.teacher || user.role === utils.roles.admin;
    },
    isAdmin: () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return parseInt(user.role) === parseInt(roles.admin);
    },
    parseUser: () => {
        return JSON.parse(localStorage.getItem("user"));
    },
    openConfirmDelete: (textDelete, onClose, param = null, event = null) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        store.dispatch(openDialog({textDelete, onClose, param}));
    }
};

export default utils;
export const {roles, rolesNames, makeOptionsItems, parseUser, openConfirmDelete} = utils;
