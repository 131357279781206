import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {resetDialog} from "store/modules/DeleteDialog";

const ConfirmDeleteDialog = (
    {
        open = false,
        textDelete = "general.item",
        onClose,
        param = null
    }
) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleClose = (value) => {
        onClose(value, param);
        dispatch(resetDialog());
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
            maxWidth="xs"
        >
            <DialogTitle>{t('general.confirmDelete')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('general.confirmDeleteText', {item: t(textDelete)})}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteDialog;
