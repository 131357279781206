import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import utils from "utils/utils";

const MenuTeacher = (
    {
        isMobile = false,
        handleCloseNavMenu = () => {},
        logout = () => {}
    }
) => {
    const {t} = useTranslation();
    const [elements, setElements] = useState([
        {
            to: "/teacher",
            title: t("courses.title")
        },
        {
            to: "/teacher/documents",
            title: t("documents.title")
        },
        {
            to: "/teacher/ontologies",
            title: t("ontologies.title")
        },
        {
            to: "",
            title: "Logout",
            isMobile: true,
            action: logout
        }
    ]);

    useEffect(() => {
        if (utils.isAdmin()) {
            let elementsNew = [...elements];
            elementsNew.push({
                to: "/teacher/users",
                title: t("users.title")
            });

            setElements(elementsNew);
        }
    }, []);

    return (
        <>
            {isMobile &&
                elements.map((element, i) => (
                    <Link key={i} to={element.to}>
                        <MenuItem key={i} onClick={element.action ? element.action : handleCloseNavMenu}>
                            <Typography textAlign="center">
                                <Button color="inherit">{element.title}</Button>
                            </Typography>
                        </MenuItem>
                    </Link>
                ))
            }

            {!isMobile &&
                elements.filter(el => !el.isMobile).map((element, i) => (
                    <Link key={i} to={element.to}>
                        <Button color="inherit">{element.title}</Button>
                    </Link>
                ))
            }
        </>
    );
};

export default MenuTeacher;
