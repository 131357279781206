import React from 'react';
import {miniModal, modalStyle} from "./style";
import {Modal, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {RAW_SERVER_URI} from "api";
import TooltipIcon from "components/TooltipIcon";

const ModalMoodle = (
    {
        open,
        handleClose,
        document = {},
    }
) => {
    const {t} = useTranslation();

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title-moodle"
            aria-describedby="modal-modal-description-moodle"
        >
            <Box sx={[modalStyle, miniModal]}>
                <span style={{position: 'absolute', top: '0', right: '0'}}>
                    <TooltipIcon icon={'close'} tooltipText={'general.close'} onClick={() => handleClose(true)}/>
                </span>

                <Typography id="modal-modal-title-moodle" variant={'h6'} component={'h2'}>
                    {t('documents.moodle')}
                </Typography>
                {document.moodle &&
                    <div id="modal-modal-description-moodle">
                        <ul>
                            <li>URL: {`${RAW_SERVER_URI}/${document.moodle.url}`}</li>
                            <li>Key: {document.moodle.key}</li>
                            <li>Secret: {document.moodle.secret}</li>
                        </ul>
                    </div>
                }
            </Box>
        </Modal>
    );
};

export default ModalMoodle;
