import {createTheme} from "@mui/material/styles";

export default createTheme({
    palette: {
        primary: {
            main: '#00C76D',
            contrastText: "#fff"
        },
        secondary: {
            main: '#11cb5f',
        },
    },
});
