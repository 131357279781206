import React, {useEffect} from "react";
import documentsApi from "api/routes/documents";
import {useUser} from "hooks/useUser";
import {handleDataServer, handleSuccessData} from "api";
import {useDispatch, useSelector} from "react-redux";
import {selectDocumentsUser, setDocumentsUser} from "store/modules/Documents";
import GenericRead from "components/teacher/GenericRead";
import {useTranslation} from "react-i18next";
import {openConfirmDelete} from "utils/utils";

const Documents = () => {
    const {t} = useTranslation();
    const user = useUser();
    const dispatch = useDispatch();
    const documents = useSelector(selectDocumentsUser);

    const deleteDocument = async (flag, idDocument) => {
        if (flag) {
            let result = await documentsApi.deleteDocument(user.access_token, idDocument);
            await handleDataServer(result, handleSuccessData);
            await populate();
        }
    };

    const populateDocuments = async () => {
        let documentsResult = await documentsApi.getDocumentsUser(user.access_token);
        let documentsData = await handleDataServer(documentsResult, (data) => data.data.documents);

        if (documentsData) {
            dispatch(setDocumentsUser(documentsData));
        }
    };

    const populate = async () => {
        await populateDocuments();
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <GenericRead
            title={t("documents.title")}
            titleAdd={t("documents.singleTitle")}
            linkAdd={`/teacher/documents/new`}
            iterate={documents}
            iterateKey="title"
            onDelete={(id) => openConfirmDelete("documents.singleTitle", deleteDocument, id)}
        />
    );
};

export default Documents;
