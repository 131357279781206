import React, {useState} from "react";
import loginApi from "api/routes/login";
import {Link} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {handleDataServer, handleSuccessData} from "api";

const ForgotPassword = () => {
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState("");
    const [forgotBool, setForgotBool] = useState("");

    async function handleSubmit(e) {
        e.preventDefault();
        const currentLanguage = i18n.language.substring(0, 2);
        const forgot = await handleDataServer(await loginApi.forgot(email, currentLanguage), handleSuccessData);

        if (forgot) {
            setForgotBool(true);
        }
    }

    return (
        <form className={'auth-form'}>

            {forgotBool ? (
                <><p>
                    {t("auth.forgot.instructionsEmail", {email: email})}
                </p></>
            ) : (
                <>
                    <p>{t('auth.forgotPassword')}</p>
                    <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        label={t("general.email")}
                        variant="outlined"
                        className={'marginBottom8'}
                    />

                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        {t("general.reset")}
                    </Button>
                </>)}


            <small style={{color: "gray", textAlign: "center"}}>
                <br></br>
                <Link to="/">
                    <u>{t("auth.backIndex")}</u>
                </Link>
            </small>
        </form>
    );
};

export default ForgotPassword;
