import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    open: false,
    textDelete: "general.item",
    onClose: () => {
    },
    param: null
};

const deleteDialogSlicer = createSlice({
    name: "deleteDialog",
    initialState: {...initialState},
    reducers: {
        openDialog: (state, action) => {
            state.open = true;
            state.textDelete = action.payload.textDelete;
            state.onClose = action.payload.onClose;
            state.param = action.payload.param;
        },
        resetDialog: (state, action) => {
            state.open = initialState.open;
            state.textDelete = initialState.textDelete;
            state.onClose = initialState.onClose;
            state.param = initialState.param;
        }
    },
});

// getters
export const selectDeleteDialog = (state) => state.deleteDialog;

// actions
export const {openDialog, resetDialog} = deleteDialogSlicer.actions;

// slice
export default deleteDialogSlicer;
