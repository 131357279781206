import React, {useEffect} from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";
import DlNotesVerdeHorizontal from "img/dlnotesverdehorizontal.png";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectError, setError} from "store/modules/Error";

/**
 * Erros are showed by Templates, handled by API and Redux Store.
 * @returns {JSX.Element} Element representing Auth template
 * @constructor
 */
const AuthTemplate = () => {
    const {t} = useTranslation();
    let location = useLocation();
    const dispatch = useDispatch();
    const error = useSelector(selectError);

    useEffect(() => {
        dispatch(setError(""));
    }, [location]);

    return (
        <Container className={'auth-template'}>
            <Link to={'/'}>
                <img
                    src={DlNotesVerdeHorizontal}
                    style={{width: "400px"}}
                    alt={'Logo DLNotes'}
                />
            </Link>
            <Typography variant="p" component="div" style={{marginBottom: 25}}>
                {t("brand.description")}
            </Typography>

            <Outlet/>

            <small className={'danger'}>{error.message ? error.message : error}</small>
        </Container>
    );
};

export default AuthTemplate;
