import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Card from "@mui/material/Card";
import SelectMultiple from "components/SelectMultiple/SelectMultiple";
import {useDispatch, useSelector} from "react-redux";
import {selectOntologies, setOntologies} from "store/modules/Ontologies";
import {handleDataServer, handleSuccessData} from "api";
import ontologiesApi from "api/routes/ontologies";
import documentsApi from "api/routes/documents";
import activitiesApi from "api/routes/activities";
import {useUser} from "hooks/useUser";
import {selectDocumentsLibrary, selectDocumentsUser, setDocumentsLibrary, setDocumentsUser} from "store/modules/Documents";
import utils from "utils/utils";
import {useNavigate, useParams} from "react-router-dom";
import {FormControl} from "@mui/material";
import {useTranslation} from "react-i18next";

/**
 * Faz a inserção/atualização de uma nova Atividade em um Curso
 * @returns {JSX.Element} Elemento de criação/atualização de uma Atividade
 * @constructor Sem parâmetros do component, pois são pegos pela url {@see routes.js}
 */
const NewActivity = () => {
    const {t} = useTranslation();
    const user = useUser();
    let {idCourse, idActivity} = useParams();
    const isEdit = idActivity !== undefined;

    // flags demanded for update activity route, where it is necessary to fetch ontologies first
    const [loadedOntologies, setLoadedOntologies] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ontologies = useSelector(selectOntologies);
    const documentsLibrary = useSelector(selectDocumentsLibrary);
    const documentsUser = useSelector(selectDocumentsUser);

    const fields = [
        "create_annotations", "edit_annotations", "create_free", "create_semantic", "create_replies", "edit_replies",
        "visible_everybody", "visible_groups", "visible_private", "see_all_semantic", "filter_by_user"
    ];

    const [formData, setFormData] = useState({});

    const handleFormChange = (e) => {
        const {name, value} = e.target;
        handleSpecificData(name, value);
    };

    const handleCheckboxChange = (e) => {
        const {name, checked} = e.target;
        handleSpecificData(name, checked);
    };

    const handleSpecificData = (item, value) => {
        setFormData((prev) => ({...prev, [item]: value}));
    };

    const toBoolean = (string) => {
        return string === "1";
    };

    const populateDocumentsAux = async (data) => {
        return data.data.documents;
    };

    const populateDocumentsUser = async () => {
        let documentsUserResult = await documentsApi.getDocumentsUser(user.access_token);
        let documentsUserData = await handleDataServer(documentsUserResult, populateDocumentsAux);
        if (documentsUserData) {
            dispatch(setDocumentsUser(documentsUserData));
        }
    };

    const populateDocumentsLibrary = async () => {
        let documentsLibraryResult = await documentsApi.getDocumentsLibrary(user.access_token);
        let documentsLibraryData = await handleDataServer(documentsLibraryResult, populateDocumentsAux);
        if (documentsLibraryData) {
            dispatch(setDocumentsLibrary(documentsLibraryData));
        }
    };

    const populateOntologies = async () => {
        let ontologiesResult = await ontologiesApi.getOntologies(user.access_token);
        let ontologiesData = await handleDataServer(ontologiesResult, async (data) => data.data.ontologies);
        if (ontologiesData) {
            dispatch(setOntologies(ontologiesData));
        }
    };

    const populateActivity = async () => {
        let activityResult = await activitiesApi.getActivity(user.access_token, idActivity);
        let activityData = await handleDataServer(activityResult, (data) => data.data);

        if (activityData) {
            let defaultValues = {
                name: activityData.name,
                ontology: ontologies.filter(el => el.name === activityData.ontology_name)[0].id,
                "selected-documents": activityData.selected_documents.map(document => document.id).toString(),
                "user-selected-documents": activityData.user_selected_documents.map(document => document.id).toString(),
                show_document_title: toBoolean(activityData.show_document_title),
                full_graph_view: toBoolean(activityData.full_graph_view),
                shared_knowledge_base: toBoolean(activityData.shared_knowledge_base),
                single_knowledge_base: toBoolean(activityData.single_knowledge_base),
            };
            fields.forEach(field => {
                defaultValues[`student_${field}`] = toBoolean(activityData[`student_${field}`]);
                defaultValues[`moderator_${field}`] = toBoolean(activityData[`moderator_${field}`]);
            });
            setFormData(defaultValues);
        }
    };

    const populate = async () => {
        await populateOntologies();
        await populateDocumentsLibrary();
        await populateDocumentsUser();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let form = new FormData();
        for (let key in formData) {
            let value = formData[key];
            value = value === true ? "1" :
                value === false ? "0" :
                    value;
            form.append(key, value);
        }

        let result = isEdit ?
            await activitiesApi.updateActivity(user.access_token, idCourse, idActivity, form) :
            await activitiesApi.addActivity(user.access_token, idCourse, form);

        let data = await handleDataServer(result, handleSuccessData);
        if (data) {
            navigate(`/teacher`);
        }
    };

    const setDefaultData = () => {
        let defaultValues = {
            name: "",
            ontology: "",
            "selected-documents": "",
            "user-selected-documents": "",
            show_document_title: true,
            full_graph_view: false,
            shared_knowledge_base: false,
            single_knowledge_base: false,
        };
        fields.forEach(field => {
            defaultValues[`student_${field}`] = true;
            defaultValues[`moderator_${field}`] = true;
        });
        setFormData(defaultValues);
    };

    useEffect(() => {
        // the activity must be called after the population of ontologies,
        // because in the activity it is returned only the ontology's name
        // and we need to get its id
        if (isEdit && !loaded && loadedOntologies) {
            populateActivity().then(el => el);
            setLoaded(true);
        }

        if (!loadedOntologies) {
            // make default values from fields
            setDefaultData();

            // populate from the database
            populate().then(el => el);

            setLoadedOntologies(true);
        }
    }, [ontologies]);

    return (
        <>
            <Card>
                <form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <h1>
                        {`${t(`general.${isEdit ? 'edit' : 'create'}`)} ${t('activities.titleNew')}`}
                        {isEdit && ` - ${idActivity}`}
                    </h1>


                    <h2>{t("activities.create.nameActivity")}</h2>
                    <TextField
                        label={t("general.name")}
                        name={"name"}
                        value={formData.name || ""}
                        onChange={handleFormChange}
                        variant="outlined"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={"show_document_title"}
                                checked={formData.show_document_title || false}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={t("activities.create.repeatTitleAnnotations")}
                    />

                    <h2>Ontology</h2>

                    <FormControl
                        variant="outlined"
                        style={{width: "100%"}}
                    >
                        <InputLabel id="select-ontology" className={'capitalize'}>{t("ontologies.singleTitle")}</InputLabel>
                        <Select
                            labelId={'select-ontology'}
                            label={t("ontologies.singleTitle")}
                            name={'ontology'}
                            value={formData.ontology || ""}
                            onChange={handleFormChange}
                        >
                            {ontologies && ontologies.map(ontology =>
                                <MenuItem key={ontology.id} value={ontology.id}>{ontology.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <h2>{t("activities.create.knowledgeBase")}</h2>

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={"full_graph_view"}
                                checked={formData.full_graph_view || false}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={t("activities.create.includeClassesValidation")}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={"shared_knowledge_base"}
                                checked={formData.shared_knowledge_base || false}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={t("activities.create.knowledgeBaseShare")}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={"single_knowledge_base"}
                                checked={formData.single_knowledge_base || false}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={t("activities.create.singleKnowledgeBase")}
                    />

                    <div>
                        <h2>{t("activities.create.digitalLibraryDocuments")}</h2>
                        <SelectMultiple
                            items={utils.makeOptionsItems(documentsLibrary, 'id', 'title')}
                            itemsSelected={
                                formData["selected-documents"] !== "" ?
                                    formData["selected-documents"]?.split(",").map(el => parseInt(el)) :
                                    []
                            }
                            onSelected={(elements) => handleSpecificData("selected-documents", elements.toString())}
                        />
                    </div>

                    <div>
                        <h2>{t("activities.create.yourDocuments")}</h2>
                        <SelectMultiple
                            items={utils.makeOptionsItems(documentsUser, 'id', 'title')}
                            itemsSelected={
                                formData['user-selected-documents'] !== "" ?
                                    formData['user-selected-documents']?.split(",").map(el => parseInt(el)) :
                                    []
                            }
                            onSelected={(elements) => handleSpecificData("user-selected-documents", elements.toString())}
                        />
                    </div>

                    <div>
                        <h2>{t("activities.create.permissions")}</h2>
                        {fields.map(field => (
                            <div key={field} style={{borderTop: "1px dotted #d7d7d7"}}>
                                <strong
                                    style={{
                                        width: "30vw",
                                        marginTop: "14px",
                                        display: "inline-table",
                                    }}
                                >
                                    {t(`activities.create.attributes.${field}`)}
                                </strong>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={`moderator_${field}`}
                                            checked={formData[`moderator_${field}`] || false}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label={t("general.moderator")}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={`student_${field}`}
                                            checked={formData[`student_${field}`] || false}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label={t("general.student")}
                                />
                            </div>
                        ))}
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {t(`general.${isEdit ? 'edit' : 'add'}`)}
                    </Button>
                </form>
            </Card>
        </>
    );
};

export default NewActivity;
