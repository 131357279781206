import React from "react";
import GenericAddFile from "components/teacher/GenericAddFile";
import documentsApi from "api/routes/documents";
import {useUser} from "hooks/useUser";
import {handleDataServer, handleSuccessData} from "api";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NewDocument = () => {
    const {t} = useTranslation();
    const user = useUser();
    const navigate = useNavigate();

    const handleSubmit = async (formData) => {
        let result = await documentsApi.addDocument(user.access_token, formData);
        let data = await handleDataServer(result, handleSuccessData);
        if (data) {
            navigate('/teacher/documents');
        }
    };

    return (
        <GenericAddFile
            titlePage={t("documents.singleTitle")}
            typeFiles={['html']}
            nameTitle={'title'}
            handleSubmit={handleSubmit}
        />
    );
};

export default NewDocument;
