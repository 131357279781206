import React, {useRef, useState} from "react";
import TextField from "@mui/material/TextField";
import {FileUploader} from "react-drag-drop-files";
import {Chip} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";

const GenericAddFile = (
    {
        nameTitle,
        titlePage,
        handleSubmit,
        typeFiles
    }
) => {
    const {t} = useTranslation();
    const form = useRef();
    const [title, setTitle] = useState();
    const [file, setFile] = useState(null);

    const onSubmit = (e) => {
        e.preventDefault();
        if (title !== null && file !== null) {
            handleSubmit(new FormData(form.current));
        } else {
            alert(t("general.fulfillAll"));
        }
    };

    return (
        <Card>
            <form
                style={{
                    display: "flex",
                    flexDirection: "column"
                }}
                ref={form}
            >
                <h1>{t("general.create")} {titlePage}</h1>
                <div className={'marginBottom8'}>
                    <TextField
                        label={t("general.title")}
                        variant="outlined"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        className={'marginBottom8 width100'}
                        name={nameTitle}
                    />

                    <FileUploader
                        required
                        name={'file'}
                        label={t("dragDropFile.title")}
                        hoverTitle={t("dragDropFile.hoverTitle")}
                        types={typeFiles}
                        onTypeError={() => alert(t("dragDropFile.invalidFileType"))}
                        handleChange={(file) => setFile(file)}
                        className={'maxWidth100'}
                    >
                        {
                            file &&
                            <div>
                                <Chip
                                    label={file.name}
                                    variant={'outlined'}
                                    onDelete={() => setFile(null)}
                                />
                            </div>
                        }
                    </FileUploader>
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    {t("general.add")}
                </Button>
            </form>
        </Card>
    );
};

export default GenericAddFile;
