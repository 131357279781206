import React from "react";
import {useUser} from "hooks/useUser";
import {useNavigate} from "react-router-dom";
import ontologiesApi from "api/routes/ontologies";
import {handleDataServer, handleSuccessData} from "api";
import GenericAddFile from "components/teacher/GenericAddFile";
import {useTranslation} from "react-i18next";

const NewOntology = () => {
    const {t} = useTranslation();
    const user = useUser();
    const navigate = useNavigate();

    const handleSubmit = async (formData) => {
        let result = await ontologiesApi.addOntology(user.access_token, formData);
        let data = await handleDataServer(result, handleSuccessData);
        if (data) {
            navigate('/teacher/ontologies');
        }
    };

    return (
        <GenericAddFile
            titlePage={t("ontologies.singleTitle")}
            typeFiles={['mm', 'html']}
            nameTitle={'name'}
            handleSubmit={handleSubmit}
        />
    );
};

export default NewOntology;
