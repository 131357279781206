import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import loginApi from "api/routes/login";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useUser} from "hooks/useUser";
import {useTranslation} from "react-i18next";
import {handleDataServer, successCallback} from "api";
import utils from "utils/utils";
import {setError} from "store/modules/Error";
import {useDispatch} from "react-redux";

const Login = () => {
    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState("");
    const [pass, setPassword] = useState("");
    const navigate = useNavigate();
    const user = useUser();
    const dispatch = useDispatch();

    async function handleSubmit(e) {
        e.preventDefault();
        const currentLanguage = i18n.language.substring(0, 2);
        const auth = await handleDataServer(await loginApi.auth(email, pass, currentLanguage), successCallback);

        if (auth && auth.data.access_token) {
            localStorage.setItem("user", JSON.stringify(auth.data));
            navigate(0);
        }
    }

    useEffect(() => {
        if (user) {
            if (user.role in utils.rolesNames) {
                if (utils.isAdmin()) {
                    navigate('/teacher');
                } else {
                    navigate(`/${utils.rolesNames[user.role]}`);
                }
            } else {
                const logout = async () => {
                    await loginApi.logout(user.access_token);
                    navigate("/");
                };

                dispatch(setError(t("auth.roleFailed")));
                logout().then(r => r);
            }
        }
    }, []);

    return (
        <form
            className={'auth-form'}
            onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
        >
            <Typography variant="p" component="div" style={{marginBottom: 25}}>
                Login
            </Typography>

            <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label={t("general.email")}
                variant="outlined"
                className={'marginBottom8'}
            />
            <TextField
                value={pass}
                onChange={(e) => setPassword(e.target.value)}
                label={t("general.password")}
                type="password"
                variant="outlined"
                className={'marginBottom8'}
            />

            <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
            >
                {t("auth.login")}
            </Button>

            <small style={{color: "gray", textAlign: "center"}}>
                <br></br>
                <Link to="/create">
                    <u>
                        {t("auth.createAccount")}
                    </u>
                </Link>
                <br></br>
                <Link to="/forgot">
                    <u>
                        {t("auth.forgotPassword")}
                    </u>
                </Link>
            </small>
        </form>
    );
};

export default Login;
