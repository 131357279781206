import React from "react";
import utils from "utils/utils";
import Template from "pages/common/Template";
import {Navigate} from "react-router-dom";

const ProtectedTeacher = () => {

    return (
        <>
            {utils.isProtected() ?
                <Template/> :
                <Navigate to={'/'}/>
            }
        </>
    );
};

export default ProtectedTeacher;
