import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import DlNotesBrancoHorizontal from "img/dlnotesbrancohorizontal.png";
import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import utils from "utils/utils";
import MenuTeacher from "components/teacher/MenuTeacher";
import loginApi from "api/routes/login";
import {useUser} from "hooks/useUser";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectError, setError} from "store/modules/Error";
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from "@mui/material/MenuItem";
import AvatarUser from "components/AvatarUser";
import {Stack} from "@mui/material";
import {selectDeleteDialog} from "store/modules/DeleteDialog";
import ConfirmDeleteDialog from "components/ConfirmDeleteDialog";


/**
 * Errors are shown by Templates, handled by API and Redux Store.
 * The delete confirm dialog is also show by Templates, handled by Redux Store.
 * @returns {JSX.Element} Element representing general student/teacher template
 * @constructor
 */
const Template = () => {
    const {t} = useTranslation();
    const user = useUser();
    const navigate = useNavigate();

    let location = useLocation();
    const dispatch = useDispatch();
    const error = useSelector(selectError);
    const deleteDialog = useSelector(selectDeleteDialog);

    // navbar behaviour
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    async function logout(e) {
        e.preventDefault();
        await loginApi.logout(user.access_token);
        navigate("/");
    }

    useEffect(() => {
        dispatch(setError(""));
    }, [location]);

    return (
        <>
            <AppBar position="static" color={"primary"}>
                <Container maxWidth={'xl'}>
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <Link to={utils.isProtected() ? `/teacher` : `/student`}>
                            <img
                                src={DlNotesBrancoHorizontal}
                                alt={t("common.template.logo")}
                                height={40}
                            />
                        </Link>

                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {utils.isProtected() &&
                                    <MenuTeacher isMobile handleCloseNavMenu={handleCloseNavMenu} logout={logout}/>
                                }
                            </Menu>
                        </Box>

                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}></Box>

                        <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                            <Stack direction={'row'}>
                                {utils.isProtected() && <MenuTeacher/>}
                                <div
                                    id={'basic-button'}
                                    aria-controls={menuOpen ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={menuOpen ? 'true' : undefined}
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                    className={'pointer fade'}
                                >
                                    <AvatarUser className={'pointer fade'}/>
                                </div>
                            </Stack>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem onClick={logout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container className={'ar-container'}>
                <Outlet/>

                <ConfirmDeleteDialog
                    open={deleteDialog.open}
                    textDelete={deleteDialog.textDelete}
                    onClose={deleteDialog.onClose}
                    param={deleteDialog.param}
                />

                <small className={'danger'}>{error.message ? error.message : error}</small>
            </Container>
        </>
    );
};

export default Template;
