import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import {handleDataServer} from "api";
import usersApi from "api/routes/users";
import loginApi from "api/routes/login";
import {useNavigate} from "react-router-dom";
import {Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useUser} from "hooks/useUser";
import UsersTable from "components/teacher/UsersTable";
import EditUserModal from "components/teacher/modal/EditUserModal";

const ChangeRoleUser = () => {
    const {t} = useTranslation();
    const [users, setUsers] = useState([]);
    const [usersShow, setUsersShow] = useState([]);
    const [search, setSearch] = useState("");

    const user = useUser();
    const navigate = useNavigate();

    const [modalEdit, setModalEdit] = useState(false);
    const [userEdit, setUserEdit] = useState(false);

    async function logout() {
        await loginApi.logout();
        navigate("/");
    }

    const changeSearch = (e) => {
        let term = e.target.value.toLowerCase();

        setSearch(term);

        let usersNow = users.filter(el => {
            return el.name.toLowerCase().includes(term) ||
                el.email.toLowerCase().includes(term);
        });

        setUsersShow(usersNow);
    };

    const handleEdit = (id) => {
        setUserEdit(users.find(el => el.id === id));
        setModalEdit(true);
    };

    const closeModal = async (update) => {
        setModalEdit(false);

        if (update && userEdit.id === user.id) {
            alert(t("users.changeItself"));
            await logout();
            return;
        }

        if (update) {
            await populate();
        }

        setUserEdit(false);
    };

    const populate = async () => {
        let usersData = await handleDataServer(await usersApi.getUsers(user.access_token), (dataServer) => dataServer.data.users);

        if (usersData) {
            setSearch("");
            setUsers(usersData);
            setUsersShow(usersData);
        }
    };

    useEffect(() => {
        populate().then(el => el);
    }, []);

    return (
        <Card>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <h1>{t("users.read.title")}</h1>

                <TextField
                    style={{marginTop: '10px', width: '50%'}}
                    label={t("users.addUsers.search")}
                    variant={"outlined"}
                    value={search}
                    onChange={changeSearch}
                    onKeyUp={changeSearch}
                />
            </Stack>

            <UsersTable
                hasRole={true}
                users={usersShow}
                addActions={true}
                isCourse={false}
                showChips={false}
                handleEdit={handleEdit}
            />

            <EditUserModal
                open={modalEdit}
                handleClose={closeModal}
                user={userEdit}
                isCourse={false}
            />
        </Card>
    );
};

export default ChangeRoleUser;
