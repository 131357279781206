import axios from "axios";
import {failureCallback, getUrl, successCallback} from "../index";

export default {
    addMembersCourse: async(token, idCourse, idUser, role) => {
        return await axios.get(getUrl('addMember'), {
            params: {
                token: token,
                course: idCourse,
                user: idUser,
                role: role
            }
        }).then(successCallback, failureCallback);
    },
    getMembersCourse: async(token, idCourse) => {
        return await axios.get(getUrl('getMembers'), {
            params: {
                token: token,
                course: idCourse
            }
        }).then(successCallback, failureCallback);
    },
    removeMemberCourse: async(token, idCourse, idUser, role) => {
        return await axios.get(getUrl('removeMember'), {
            params: {
                token: token,
                course: idCourse,
                user: idUser,
                role: role
            }
        }).then(successCallback, failureCallback);
    },
    editMember: async(token, idCourse, idUser, role) => {
        return await axios.get(getUrl('editMember'), {
            params: {
                token: token,
                course: idCourse,
                user: idUser,
                role: role
            }
        }).then(successCallback, failureCallback);
    },
    searchUsers: async(token, term) => {
        return await axios.get(getUrl('searchUsers'), {
            params: {
                token: token,
                term: term
            }
        }).then(successCallback, failureCallback);
    },

    // admin only
    getUsers: async(token) => {
        return await axios.get(getUrl('getUsers'), {
            params: {
                token: token
            }
        }).then(successCallback, failureCallback);
    },
    editUser: async(token, user, role = null, email = null) => {
        return await axios.get(getUrl('editUser'), {
            params: {
                token: token,
                user: user,
                role: role,
                email: email,
            }
        });
    },
};
