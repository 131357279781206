import {useTranslation} from "react-i18next";
import {IconButton, Tooltip} from "@mui/material";
import Icon from "@mui/material/Icon";
import React from "react";

const TooltipIcon = (
    {
        tooltipText,
        icon,
        color = 'inherit',
        onClick = () => {}
    }
) => {
    const {t} = useTranslation();

    return (
        <Tooltip title={t(tooltipText) } onClick={onClick}>
            <IconButton>
                <Icon color={color}>{ icon }</Icon>
            </IconButton>
        </Tooltip>
    );
};

export default TooltipIcon;
