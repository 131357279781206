import {Link, useNavigate} from "react-router-dom";
import React from "react";
import coursesApi from 'api/routes/courses';
import {useUser} from "hooks/useUser";
import TooltipIcon from "../TooltipIcon";
import {openConfirmDelete} from "utils/utils";

const CourseManagement = (props) => {
    const {course} = props;
    const user = useUser();
    const navigate = useNavigate();

    const deleteCourse = async (flag) => {
        if (flag) {
            await coursesApi.deleteCourse(user.access_token, course.id).then(() => {
                navigate(0);
            });
        }
    };

    return (
        <div className={'button-container'}>
            <Link to={`/teacher/courses/${course.id}/edit`}>
                <TooltipIcon tooltipText={"courses.edit"} icon={"edit"} color={'primary'}/>
            </Link>
            <Link to={''} onClick={(e) => openConfirmDelete("courses.titleNew", deleteCourse, null, e)}>
                <TooltipIcon tooltipText={"general.delete"} icon={"delete"} color={'primary'}/>
            </Link>
            <Link to={`/teacher/courses/${course.id}/activities`}>
                <TooltipIcon tooltipText={"activities.title"} icon={"library_books"} color={'primary'}/>
            </Link>
            <Link to={`/teacher/courses/${course.id}/users`}>
                <TooltipIcon tooltipText={"users.title"} icon={"people"} color={'primary'}/>
            </Link>
        </div>
    );
};

export default CourseManagement;
