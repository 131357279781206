export default {
    translations: {
        brand: {
            name: "DLNotes",
            description: "Collaborative annotation tool in digital content for language teaching",
        },
        auth: {
            login: "Login",
            createAccount: "Create account",
            alreadyHaveAccount: "Already have an account",
            backIndex: "Go back to main page",
            logout: "Logout",
            roleFailed: "It looks like your profile role does not exist. Contact a system administrator.",
            forgotPassword: "Forgot my password",
            forgot: {
                instructionsEmail: "The recovery instructions were sent to {{email}}"
            },
            confirm: {
                title: "Set an access password",
                passwordSet: "Password set successfully, you will be redirected to the login page",
                confirmPassword: "Confirm the password",
                setPassword: "Set password",
                passwordDiff: "Passwords do not match"
            },
            create: {
                title: "Create user",
                line0: "It is very good to have you here!",
                line1: "You will receive an email to confirm your registration, see you later!",
                fullName: "Full name"
            }
        },
        common: {
            template: {
                logo: "DLNotes Logo"
            }
        },
        teacher: {
            exportAnnotations: "Export annotations",
        },
        stats: {
            stats_free: "Free statistics",
            stats_semantic: "Semantic statistics",
            telemetry: "Telemetry",
        },
        activities: {
            title: "Activities",
            titleNew: "activity",
            read: {
                newActivity: "New activity",
                courseActivities: "Course activities",
                orphanActivities: "Orphan activities",
                deleteActivity: "Delete activity"
            },
            create: {
                nameActivity: "Activity's name",
                repeatTitleAnnotations: "Repeat document title in annotations board",
                knowledgeBase: "Knowledge base",
                knowledgeBaseShare: "Knowledge bases are shared between activity users",
                singleKnowledgeBase: "A knowledge base for all documents",
                includeClassesValidation: "Include classes in validation",
                digitalLibraryDocuments: "Digital library documents",
                yourDocuments: "Your documents",
                permissions: "Permissions",
                attributes: {
                    create_annotations: "Create annotations",
                    edit_annotations: "Edit annotations",
                    create_free: "Create free annotations",
                    create_semantic: "Create semantic annotations",
                    create_replies: "Create replies",
                    edit_replies: "Edit replies",
                    visible_everybody: "Make annotations with 'public' visibility",
                    visible_groups: "Make annotations with 'moderator' visibility",
                    visible_private: "Make annotations with 'private' visibility",
                    see_all_semantic: "See other members of the knowledge base",
                    filter_by_user: "Allow filtering of free annotations by user"
                }
            }
        },
        users: {
            title: "Users",
            singleTitle: "user",
            addUsers: {
                title: "Add users",
                search: "Search for users",
                role: "Role"
            },
            read: {
                title: "Users management",
                table: {
                    id: "ID",
                    name: "Name",
                    email: "Email",
                    role: "Role"
                }
            },
            edit: {
                title: "Edit user"
            }
        },
        courses: {
            title: "Courses",
            titleNew: "course",
            create: {
                nameCourse: "Course's name",
            },
            edit: "Rename course"
        },
        documents: {
            title: "Documents",
            singleTitle: "document",
            moodle: "Configuration information for extern activity on Moodle"
        },
        ontologies: {
            title: "Ontologies",
            singleTitle: "ontology"
        },
        general: {
            name: "Name",
            moderator: "Moderator",
            student: "Student",
            create: "Create",
            add: "Add",
            edit: "Edit",
            delete: "Delete",
            reset: "Reset",
            email: "Email",
            password: "Password",
            alert: "Ops, it was not possible to complete the request",
            confirmDelete: "Do you really want to delete?",
            confirmDeleteText: "Do you really want to delete this {{item}}?",
            fulfillAll: "You must fulfill all the fields",
            title: "Title",
            actions: "Actions",
            item: "Item",
            close: "Close",
        },
        genericRead: {
            notFound: "No {{title}} found"
        },
        roles: {
            teacher: "Teacher",
            student: "Student",
            moderator: "Moderator",
            administrator: "Administrator"
        },
        selectMultiple: {
            leftTitle: "Available",
            rightTitle: "Selected"
        },
        dragDropFile: {
            title: "Drag or click to select",
            hoverTitle: "Drag here",
            invalidFileType: "Invalid file type"
        }
    },
};
