import React, {useState} from "react";
import {FormControl, Modal} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import style from "./style";
import {useUser} from "hooks/useUser";
import UsersTable from "../UsersTable";
import usersApi from "api/routes/users";
import {handleDataServer, handleSuccessData} from "api";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RolesSelect from "components/teacher/RolesSelect";
import TooltipIcon from "components/TooltipIcon";

const AddUserModal = (
    {
        open,
        handleClose,
        usersAdded = []
    }
) => {
    const {t} = useTranslation();
    const user = useUser();
    let {idCourse} = useParams();

    const [usersSearch, setUsersSearch] = useState([]);
    const [role, setRole] = useState("");
    const [selected, setSelected] = useState([]);

    const handleChange = async (e) => {
        const term = e.target.value;

        if (term.length >= 3) {
            let usersResult = await usersApi.searchUsers(user.access_token, e.target.value);
            let usersData = await handleDataServer(usersResult, (data) => data.data.members);

            if (usersData) {
                // remove already added users from search
                usersAdded.forEach(user => {
                    usersData = usersData.filter(el => el.id !== user.id);
                });

                // set usersSearch
                setUsersSearch(usersData);
            }
        }
    };

    const zeroState = () => {
        setUsersSearch([]);
        setRole("");
        setSelected([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // check if fields are fulfilled
        if (role && selected) {
            let answer = true;

            for (let i = 0; i < selected.length; i++) {
                let id = selected[i];
                let result = await usersApi.addMembersCourse(user.access_token, idCourse, id, role);
                answer &= await handleDataServer(result, handleSuccessData);
            }

            // if all went well, zero the state and close the modal
            if (answer) {
                zeroState();
                handleClose(true);
            }
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <span style={{position: 'absolute', top: '0', right: '0'}}>
                    <TooltipIcon icon={'close'} tooltipText={'general.close'} onClick={() => handleClose(true)}/>
                </span>

                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t("users.addUsers.title")}
                </Typography>
                <div id="modal-modal-description">
                    <Grid container spacing={2} alignItems={'center'} columns={{xs: 4, sm: 4, md: 12}}>
                        <Grid item xs={6}>
                            <TextField
                                label={t("users.addUsers.search")}
                                variant={"outlined"}
                                sx={{width: '100%'}}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                variant="outlined"
                                style={{width: "100%"}}
                            >
                                <InputLabel id="select-role">{t('users.addUsers.role')}</InputLabel>
                                <RolesSelect role={role} setRole={setRole}/>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {t("general.add")}
                            </Button>
                        </Grid>
                    </Grid>

                    <UsersTable
                        users={usersSearch}
                        hasCheckbox={true}
                        hasRole={false}
                        onSelection={(selectedNow) => setSelected(selectedNow)}
                    />
                </div>
            </Box>
        </Modal>
    );
};

export default AddUserModal;
