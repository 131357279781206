import React, {useEffect, useState} from "react";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import utils from "utils/utils";
import Checkbox from "@mui/material/Checkbox";
import {useTranslation} from "react-i18next";
import TooltipIcon from "components/TooltipIcon";

const tdStyle = {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
};

const UsersTable = (
    {
        users,
        hasCheckbox = false,
        addActions = false,
        hasRole = true,
        isCourse = true,
        showChips = true,
        onSelection,
        handleDelete,
        handleEdit
    }
) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState([]);
    const [columns, setColumns] = useState([
        {field: "name", headerName: t("users.read.table.name"), sortable: true},
        {field: "email", headerName: t("users.read.table.email"), sortable: true}
    ]);
    const [done, setDone] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const handleCheckboxSelection = (id) => {
        let selectedNow = [...selected];
        if (selectedNow.includes(id)) {
            selectedNow.splice(selectedNow.indexOf(id), 1);
        } else {
            selectedNow.push(id);
        }
        setSelected(selectedNow);

        // call parent function
        onSelection(selectedNow);
    };

    const getColumns = <>
        <TableRow>
            {hasCheckbox &&
                <TableCell></TableCell>
            }

            {
                columns.map((column, index) =>
                    <TableCell key={index} align="center">{column.headerName}</TableCell>
                )
            }

            {
                addActions &&
                <TableCell>{t("general.actions")}</TableCell>
            }
        </TableRow>
    </>;

    const getRows = users && users.length ?
        users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((user, index) =>
            <TableRow key={index}>
                {hasCheckbox &&
                    <TableCell>
                        <Checkbox
                            checked={selected.includes(user.id)}
                            onChange={() => handleCheckboxSelection(user.id)}
                        />
                    </TableCell>
                }

                {columns.map((column, index1) =>
                    <TableCell key={index1} align="center" sx={tdStyle}>
                        {column.field === "role" ?
                            isCourse ?
                                t(`roles.${utils.rolesCoursesNames[parseInt(user["role"])]}`) :
                                t(`roles.${utils.rolesNames[parseInt(user["role"])]}`)
                            :
                            user[column.field]
                        }
                    </TableCell>
                )}

                {
                    addActions &&
                    <>
                        <TableCell>
                            {/* we do not edit a teacher when editing showing a course */}
                            {(handleEdit || (isCourse && !user.role !== "3")) &&
                                <TooltipIcon
                                    onClick={() => handleEdit(user.id)}
                                    icon={'edit'}
                                    tooltipText={"general.edit"}
                                />
                            }
                            {handleDelete &&
                                <TooltipIcon
                                    onClick={() => handleDelete(user.id)}
                                    icon={'delete'}
                                    tooltipText={"general.delete"}
                                />
                            }
                        </TableCell>
                    </>
                }
            </TableRow>
        ) : [];

    useEffect(() => {
        if (hasRole && !done) {
            setColumns([...columns, {field: "role", headerName: t('users.read.table.role'), sortable: true}]);
            setDone(true);
        }
    }, []);

    return (
        <>
            <div style={{marginTop: '12px', marginBottom: '12px'}}>
                {(selected && showChips) &&
                    selected.map(id =>
                        <Chip
                            key={id}
                            className={'pointer'}
                            label={users.find(el => el.id === id).name}
                            onClick={() => handleCheckboxSelection(id)}
                            onDelete={() => handleCheckboxSelection(id)}
                        />
                    )}
            </div>
            <TableContainer component={Paper} sx={{maxHeight: 400}}>
                <Table className={'thead-bold'}>
                    <TableHead>
                        {getColumns}
                    </TableHead>
                    <TableBody>
                        {getRows}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users ? users.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default UsersTable;
