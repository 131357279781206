import React from "react";
import "./i18n";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import Routes from "./routes";
import theme from "./theme";
import {ThemeProvider} from '@mui/material/styles';
import {RouterProvider} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <RouterProvider router={Routes}/>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);
