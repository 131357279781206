import axios from "axios";
import {failureCallback, getUrl, successCallback} from "../index";

export default {
    addActivity: async (token, idCourse, form) => {
        return await axios.post(getUrl(`createActivity`), form, {
                method: 'POST',
                data: form,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    token: token,
                    course: idCourse,
                },
            }).then(successCallback, failureCallback);
    },
    updateActivity: async (token, idCourse, idActivity, form) => {
        return await axios.post(getUrl('updateActivity'), form, {
            method: 'POST',
            data: form,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            params: {
                token: token,
                course: idCourse,
                activity: idActivity
            },
        }).then(successCallback, failureCallback);
    },
    getOrphanActivities: async (token) => {
        return await axios.get(getUrl('getOrphansActivities'), {
            params: {
                token: token
            }
        }).then(successCallback, failureCallback);
    },
    getActivities: async (token, idCourse) => {
        return await axios.get(getUrl('getActivities'), {
            params: {
                token: token,
                course: idCourse
            }
        }).then(successCallback, failureCallback);
    },
    getActivity: async (token, idActivity) => {
        return await axios.get(getUrl('getActivity'), {
            params: {
                token: token,
                activity: idActivity
            }
        }).then(successCallback, failureCallback);
    },
    removeActivityCourse: async (token, idCourse, idActivity) => {
        return await axios.get(getUrl('removeActivity'), {
            params: {
                token: token,
                course: idCourse,
                activity: idActivity,
            }
        }).then(successCallback, failureCallback);
    },
    addActivityCourse: async (token, idCourse, idActivity) => {
        return await axios.get(getUrl('addActivity'), {
            params: {
                token: token,
                course: idCourse,
                activity: idActivity,
            }
        }).then(successCallback, failureCallback);
    },
    deleteActivity: async (token, idActivity) => {
        return await axios.get(getUrl('deleteActivity'), {
            params: {
                token: token,
                activity: idActivity,
            }
        }).then(successCallback, failureCallback);
    },
};
