import React from 'react';
import utils from "utils/utils";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {useTranslation} from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import {FormControl} from "@mui/material";

const RolesSelect = (
    {
        role,
        setRole,
        isCourse = true
    }
) => {
    const {t} = useTranslation();

    const getRoles = () => {
        const roles = isCourse ? {...utils.rolesCoursesNames} : {...utils.rolesNames};

        return Object.keys(roles);
    };

    return (
        <Select
            labelId={'select-role'}
            label={t("users.addUsers.role")}
            name={'role'}
            variant={'outlined'}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            width={'100%'}
        >
            {getRoles().map((valueRole) =>
                <MenuItem key={valueRole} value={valueRole}>
                    {t(`roles.${isCourse ? utils.rolesCoursesNames[valueRole] : utils.rolesNames[valueRole]}`)}
                </MenuItem>
            )}
        </Select>
    );
};

export default RolesSelect;
